function Work() {
    return (
        <section id='sect_xxx_work' className='px-4 mb-12 sm:p-0 sm:px-4 md:px-8'> {/** @todo: padding/margin size  px-4 mb-12 sm:p-0 sm:px-4 md:px-8**/}
            <div className='grid grid-cols-1 gap-4 lg:grid-cols-2'>
                <div className='col-span-1'>
                    <h2 className='pb-4 sm:pb-6 text-2xl font-extrabold tracking-tight text-stone-200 sm:text-3xl'>
                        <span className='text-green-700' id="colored-text">Szak</span>
                        mai tapasztalat
                    </h2>
                </div>
            </div>
        </section>
    )
}



export default Work
